<template>
  <div>
    <!-- <render-input v-if="!isServerProduction" :input="fieldConfig.useFakeApi" v-model="useFakeApi"></render-input> -->

    <master-component
      ref="masterComponent"
      :config="config"
      :showAdd="false"
      :showEdit="false"
    >
      <template v-slot:table-custom-event-button="{ item }">
        <v-btn
          small
          color="info"
          @click="seeDetailsBaiBerk(item)"
        >รายละเอียด</v-btn>

        <v-btn
          small
          color="info"
          class="ml-1"
          @click="paid(item)"
          v-if="item.canPaid"
        >จ่ายเงิน</v-btn>
      </template>
    </master-component>
  </div>
</template>

<script>
import {
  InputType,
  StatusCase,
  UserRole,
  StatusPayment,
} from "./../js/constants";

import { createDynamicModel } from "./../js/custom";

import { fakeAbbr, getFakeUrl } from "./../_helpers/fake-url";
import {
  getPaymentType,
  getLawyers,
  getBranches,
  getInstallmentPayments,
} from "../js/dropdown-service";

import UseFakeApiMixins from "./../_mixins/use-fake-api-mixins";
import { momentToThaiFormat } from "./../js/date-helper";
import moment from "moment";
import { getToken } from "../js/authorization";

export default {
  mixins: [UseFakeApiMixins],
  data() {
    return {
      config: {},
      selected: [],
      useFakeApi: false,
    };
  },
  created() {
    this.initialConfig();
  },
  methods: {
    initialConfig() {
      this.config = {
        name: "baiber-maintain",
        shortName: "baiberk maintain",
        search: this.searchSection,
        form: {},
        url: this.urlSection,
        table: this.tableSection,
        permission: {},
      };
    },

    paid(item) {
      let url = `/baiberk/${item.id}/paid`;
      // if (this.useFakeApi) {
      //   url = getFakeUrl(fakeAbbr.baiberk.paid) + url;
      // }

      this.$http.post(url).then((res) => {
        this.$refs.masterComponent.search();
        this.showAlertSuccess();
      });
    },
    seeDetailsBaiBerk(item) {
      let routeData = this.$router.resolve({
        name: "baiberk-details",
        params: {
          id: item.id,
        },
      });

      window.open(routeData.href, "_blank");
    },
  },
  computed: {
    // fieldConfig() {
    //   return {
    //     useFakeApi: {
    //       name: "useFakeApi",
    //       text: "useFakeApi",
    //       type: InputType.checkBox,
    //     },
    //   };
    // },
    urlSection() {
      let searchUrl = "/baiberk/search";
      // if (this.useFakeApi) {
      //   searchUrl = getFakeUrl(fakeAbbr.baiberk.search) + searchUrl;
      // }

      return {
        search: searchUrl,
      };
    },
    searchSection() {
      return {
        searchOnInit: false,
        rows: [
          [
            {
              name: "lawyer",
              text: "ทนาย",
              type: InputType.dropdown,
              disabled: this.isLawyer,
              data: {
                promise: getLawyers(),
              },
            },
            {
              name: "type",
              text: "ประเภท",
              type: InputType.dropdown,
              data: {
                promise: getPaymentType(),
              },
            },
            {
              name: "oa",
              text: "OA",
              type: InputType.dropdown,
              data: {
                promise: getBranches({ authorize: true }),
              },
            },
            {
              name: "installmentPayment",
              text: "ส่วน",
              type: InputType.dropdown,
              data: {
                promise: getInstallmentPayments(),
              },
            },
          ],
          [
            {
              name: "status",
              text: "สถานะเอกสาร",
              type: InputType.dropdown,
              data: {
                url: "/dropdown/payment/baiberkStatuses",
              },
            },
            {
              name: "lotNumber",
              text: "Lot Number",
              type: InputType.text,
            },
            {
              name: "blackNumber",
              text: "หมายเลขคดีดำ",
              type: InputType.text,
            },
            {
              name: "redNumber",
              text: "หมายเลขคดีแดง",
              type: InputType.text,
            },
          ],
          // [
          //   {
          //     name: "lawyer",
          //     text: "ทนาย",
          //     type: InputType.dropdown,
          //     disabled: this.isLawyer,
          //     data: {
          //       promise: getLawyers(),
          //     },
          //   },
          //   {
          //     name: "lotNumber",
          //     text: "Lot Number",
          //     type: InputType.text,
          //   },
          // ],
          // [
          //   {
          //     name: "refNumber",
          //     text: "Ref Number",
          //     type: InputType.text,
          //   },

          //   {
          //     name: "status",
          //     text: "สถานะเอกสาร",
          //     type: InputType.dropdown,
          //     data: {
          //       url: "/dropdown/payment/docstatuses",
          //     },
          //   },
          //   {
          //     type: InputType.offset,
          //   },
          // ],
          [
            {
              name: "date",
              text: " ช่วงวันที่เอกสาร",
              type: InputType.begin_end_date,
              default: {
                begin: momentToThaiFormat({
                  moment: moment().subtract(1, "months"),
                }),
                end: momentToThaiFormat({ moment: moment() }),
              },
              columnClass: "col-sm-12 col-md-6",
            },
            {
              name: "refNumber",
              text: "Ref Number",
              type: InputType.text,
            },
            {
              type: InputType.offset,
            },
          ],
        ],
      };
    },
    tableSection() {
      return {
        bulkAction: this.bulkAction,
        columns: [
          { name: "lawyerName", text: "ทนาย", style: "width : 170px" },
          { name: "type.text", text: "ประเภท", style: "width : 106px" },
          {
            name: "installmentPayment.text",
            text: "ส่วน",
            style: "width : 58px",
          },
          { name: "oa.text", text: "OA", style: "width : 58px" },
          { name: "refNumber", text: "REF", style: "width : 95px" },
          { name: "latestDate", text: "latestDate", style: "width : 95px" },
          {
            name: "recordPositive",
            text: "รายการ",
            class: "text-right",
            numeric: 0,
            style: "width : 40px",
          },
          {
            name: "grandTotal",
            text: "Total",
            numeric: 2,
            class: "text-right",
          },
          { name: "status.text", text: "สถานะ" },
        ],
      };
    },
  },
};
</script>
